import React from "react";
import Card from "./Card";
import seo from "../../../assets/passport.png";
import printer from "../../../assets/printer.jpg";
import binding from "../../../assets/spirel binding.webp";
import jobs from "../../../assets/job forms.jpg";
import money from "../../../assets/banking.webp";
import car from "../../../assets/two nad four incurrance.png";
import dl from "../../../assets/dl.jpeg";
import adhar from "../../../assets/adhar card.webp";
import pan from "../../../assets/pancard.jpeg";
import scanner from "../../../assets/scaner.jpg";
import lamination from "../../../assets/lamination.jpg";
import schoolProjects from "../../../assets/projects.webp";
import xerox from '../../../assets/xerox.png'
import training from '../../../assets/project report.jpg'
import typing from '../../../assets/typing work.jpg'
import epf from '../../../assets/EPF.jpeg'
import jobplacement from '../../../assets/Job-Placement.png'
import itr from '../../../assets/itr.jpg'

const GeneralServiceCards = () => {
  return (
    <div className="p-4">
      <h1 className="mt-4 bg-blue-600 text-white py-2 px-6 rounded-md text-center text-xl mx-auto block hover:bg-blue-700 transition duration-300 ease-in-out">
        Online Services
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <Card
          image={seo} // Replace with your image path
          title="Online Passport Application"
          description="Our online passport application services simplify the process of obtaining your passport from the comfort of your home. We guide you through each step, ensuring all necessary documents are correctly submitted for a smooth application experience. With our assistance, you can achieve your travel goals without the usual hassles of passport processing."
          link="/contact"
        />
        <Card
          image={pan} // Replace with your image path
          title="Online Pan Card Application"
          description="Our online PAN card application services make it easy to apply for your Permanent Account Number from the comfort of your home. We provide step-by-step guidance to help you complete your application accurately and efficiently. With our support, you can receive your PAN card hassle-free, ensuring you meet all financial regulations."
          link="/contact"
        />
        <Card
          image={adhar} // Replace with your image path
          title="Online Adhar Card Update"
          description="Our online Aadhaar update services simplify the process of modifying your Aadhaar details from the comfort of your home. We guide you through the necessary steps to ensure your information is accurate and up to date. With our assistance, you can complete your Aadhaar updates efficiently and hassle-free."
          link="/contact"
        />
        <Card
          image={dl} // Replace with your image path
          title="Online Learning Licence "
          description="Our online learning license services offer a convenient way for individuals to acquire their learning licenses from the comfort of their homes. We provide comprehensive guidance and resources, ensuring that applicants are well-prepared for the necessary tests. Experience a streamlined process with expert support, making your journey to obtaining a learning license hassle-free."
          link="/contact"
        />
        <Card
          image={printer} // Replace with your image path
          title="Color Printing"
          description="Our color printing services deliver vibrant and professional-quality prints for all your needs, whether it's marketing materials, presentations, or personal projects. We use high-quality inks and state-of-the-art printers to ensure that your colors pop and your images are sharp. With quick turnaround times and competitive pricing, we make it easy to bring your ideas to life."
          link="/contact"
        />
        <Card
          image={xerox} // Replace with your image path
          title="Bulk Xerox"
          description="Our bulk Xerox services provide fast and efficient copying solutions for businesses and individuals, accommodating large quantities with precision and quality. We use high-speed machines to ensure that your documents are duplicated quickly while maintaining clarity and detail. Enjoy competitive pricing and reliable service, making it easy to meet your bulk copying needs."
          link="/contact"
        />
        <Card
          image={scanner} // Replace with your image path
          title="Document Scan"
          description="Our document scanning services provide a quick and efficient way to convert physical documents into high-quality digital files. This ensures easy storage, retrieval, and sharing while reducing physical clutter. With a focus on accuracy and security, we guarantee your sensitive information is handled with care."
          link="/contact"
        />
        <Card
          image={binding} // Replace with your image path
          title="Spiral Binding"
          description="Our spiral binding services offer a practical and stylish way to bind your documents, ensuring they lay flat for easy reading. Ideal for reports, presentations, and notebooks, our binding options provide durability and a professional finish. Available in various colors and sizes, we tailor our services to meet your specific project requirements."
          link="/contact"
        />
        <Card
          image={lamination} // Replace with your image path
          title="Document Lamination"
          description="Our lamination services provide a protective, glossy finish to your documents, enhancing durability and visual appeal. Ideal for business cards, certificates, menus, and more, our lamination helps preserve the quality of your materials. Choose from various thicknesses and finishes to suit your specific needs and ensure your documents stand the test of time."
          link="/contact"
        />
        <Card
          image={jobs} // Replace with your image path
          title="All Jobs Form Filling"
          description="At Ambey Nexus, we simplify the online government job application process, ensuring your submissions are accurate and timely. Our experienced team provides guidance at every step, minimizing errors and streamlining the application journey. With our support, you can focus on preparing for your future while we handle the technical details. Trust us to make your application process smooth and hassle-free."
          link="/contact"
        />
        <Card
          image={money} // Replace with your image path
          title="Banking Services"
          description="we provide essential banking services right at your doorstep from facilitating cash deposits, withdrawals, and money transfers, we ensure secure and convenient access to banking for all. Ambey Nexus also offers loan application assistance, financial inclusion services, and digital banking support, making it easier for rural and underserved communities to manage their finances efficiently. Trust us for all your banking needs, delivered with the ease and reliability of a local service provider."
          link="/contact"
        />
        <Card
          image={car} // Replace with your image path
          title="Insurance"
          description="We offer comprehensive insurance services, including life, health, and auto coverage for two-wheelers and four-wheelers. Our home insurance protects your property from damage, while business insurance safeguards your enterprise from potential risks. Each plan is designed to offer peace of mind and financial security. Trust us to provide the protection you need, with reliable and affordable coverage options."
          link="/contact"
        />
        <Card
          image={schoolProjects} // Replace with your image path
          title="School Project Work"
          description=" We provide expert assistance for school and college projects, offering tailored support in research, design, and presentation across various subjects. Our services help students excel in academics by delivering high-quality, well-organized projects on time. Whether it's a science experiment, IT project, or arts-related work, we ensure that every project meets educational standards. Let us simplify your project work with professional guidance and creativity."
          link="/contact"
        />
        <Card
          image={training} // Replace with your image path
          title="Computer Training Basic to Advance"
          description=" Ambey Nexus offer comprehensive online and offline training in Social Media Marketing, Graphic Designing, Web Development, and Computer Courses ranging from basic to advanced levels. Our expert-led programs are designed to equip learners with practical skills and industry knowledge, making them job-ready. Whether you're a beginner or looking to enhance your expertise, our flexible training options cater to all. Join us to upskill and excel in today's digital landscape!"
          link="/contact"
        />
        <Card
          image={typing} // Replace with your image path
          title="Typing Work"
          description=" Ambey Nexus offer fast and accurate typing services in English, Hindi, and Punjabi, catering to all your document needs. Whether it's academic papers, official documents, or personal typing tasks, we ensure timely and error-free completion. Our team is proficient in various formats and styles to meet your specific requirements. Trust us for high-quality, multilingual typing solutions"
          link="/contact"
        />
        <Card
          image={epf} // Replace with your image path
          title="EPF Services"
          description=" Our EPF withdrawal services offer a streamlined online application process, allowing members to access their provident fund savings easily for various needs like retirement, housing, or emergencies. With real-time tracking and expert guidance, we ensure a secure and efficient experience. Trust us to facilitate timely disbursement of your funds, prioritizing transparency and customer satisfaction."
          link="/contact"
        />
        <Card
          image={itr} // Replace with your image path
          title="Income Tax Return"
          description=" Our income tax return services provide comprehensive assistance in preparing and filing your tax returns accurately and on time. We offer expert guidance on deductions, credits, and tax planning to maximize your refunds and minimize liabilities. Enjoy a hassle-free experience with real-time tracking and personalized support every step of the way"
          link="/contact"
        />
        <Card
          image={jobplacement} // Replace with your image path
          title="Job Placement"
          description="Our job placement services connect job seekers with ideal employment opportunities, providing personalized career guidance and resume support. We leverage our extensive network of employers to match candidates with positions that align with their skills and aspirations. With dedicated support throughout the hiring process, we ensure a seamless transition into your new role."
          link="/contact"
        />
      </div>
    </div>
  );
};

export default GeneralServiceCards;
