
import { Link } from "react-router-dom"
const Card = ({ image, title, description, link }) => {
    return (
      <div className="bg-white rounded-lg shadow-lg overflow-hidden transition-transform transform hover:scale-105">
        <img src={image} alt={title} className="w-full h-80 object-fit" />
        <div className="p-4">
          <h3 className="text-orange-600 font-bold text-[1.5rem] font-crimson">{title}</h3>
          <p className="text-black-600 mt-2 font-crimson text-[1.3rem]">{description}</p>
          <Link
            to={link}
            className="mt-4 inline-block text-blue-600 hover:underline font-bold"
          >
            Contact us
          </Link>
        </div>
      </div>
    );
  };
  
  export default Card;