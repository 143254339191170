import React, { useState } from 'react';
import { motion } from 'framer-motion';

const faqs = [
  {
    question: "What is included in your Digital Marketing Services?",
    answer:
      "Our digital marketing services include social media marketing, search engine optimization (SEO), content marketing, paid advertising, and analytics tracking to help grow your brand online.",
  },
  {
    question: "What computer courses do you offer?",
    answer:
      "We offer courses ranging from basic computer skills to advanced topics such as MS Office, Excel, and networking fundamentals. Each course is designed to build strong foundational skills.",
  },
  {
    question: "How long are the training courses?",
    answer:
      "Our training courses typically last for 45 days, with both practical and theoretical lessons to ensure a comprehensive learning experience.",
  },
  {
    question: "Do you provide support for Aadhar card updates and form filling?",
    answer:
      "Yes, we assist with Aadhar card updates, form filling for government services, and a variety of other support services to make the process easy for you.",
  },
  {
    question: "Do you offer certifications upon course completion?",
    answer:
      "Yes, we provide certifications after the successful completion of our computer courses, digital marketing programs, and workshops.",
  },
];

const FAQPage = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="bg-gradient-to-r from-orange-100 via-orange-200 to-pink-200 p-8 min-h-screen">
      <motion.h1
        className="text-5xl font-bold text-center text-purple-700 mb-12"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        Frequently Asked Questions
      </motion.h1>

      <div className="max-w-4xl mx-auto">
        {faqs.map((faq, index) => (
          <motion.div
            key={index}
            className={`mb-4 p-4 rounded-lg shadow-lg ${
              activeIndex === index ? "bg-white" : "bg-purple-100"
            }`}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
          >
            <div
              className="cursor-pointer text-xl font-semibold text-purple-700 flex justify-between items-center font-crimson text-[1.4rem]"
              onClick={() => toggleFAQ(index)}
            >
              {faq.question}
              <span className="text-purple-500">
                {activeIndex === index ? "-" : "+"}
              </span>
            </div>
            {activeIndex === index && (
              <motion.p
                className="mt-4 text-gray-700 font-crimson text-[1.4rem]"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
              >
                {faq.answer}
              </motion.p>
            )}
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default FAQPage;
