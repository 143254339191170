import React from 'react'
import Tabs from './tabs/Tabs'

const Servises = () => {
  return (
  
    <>
    <Tabs />
    </>
  )
}

export default Servises