import React from 'react';
import Card from './Card';
import seo from "../../../assets/seo.jpg"
import gd from  '../../../assets/gd.png'
import smm from '../../../assets/smm.webp'
import webdev from "../../../assets/webdev.jpg"

const ServicesCard = () => {
  return (
    <div className="p-4">
      <h2 className="mt-4 bg-blue-600 text-white py-2 px-6 rounded-md text-center text-xl mx-auto block hover:bg-blue-700 transition duration-300 ease-in-out">IT Services</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <Card
          image={webdev} // Replace with your image path
          title="Web Development"
          description="If your business is not having a website this days means missing all the benefits you could get from online space. A website helps your business to showcase your whole business from your products to services."
          link="/contact"
        />
        <Card
          image={gd} // Replace with your image path
          title="Graphics Designing"
          description="The main purpose of Graphic design is to communicate. Primarily graphic design is visual communication; it uses typography, images and colours to represent certain ideas or messages. Start Today"
          link="/contact"
        />
        <Card
          image={seo} // Replace with your image path
          title="SEO Services"
          description="Unlock the full potential of your online presence and reach the top of search rankings with our expert Search Engine Optimization (SEO) services – drive more traffic, generate more leads, and grow your business like never before!"
          link="/contact"
        />
        <Card
          image={smm} // Replace with your image path
          title="Social Media Management"
          description="Social Media can help your business to build an identity online with the help of many social media platform available online i.e. Facebook, Instagram, Twitter, Pinterest and other. Once you’re up there you people start engaging with you.."
          link="/contact"
        />

      </div>
    </div>
  );
};

export default ServicesCard;


