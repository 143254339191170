import React from "react";
import { Link } from "react-router-dom";
import smm from "../../../assets/smm.webp"; // Proper import of the images
import webdev from "../../../assets/webdev.jpg";
import gd from "../../../assets/gd.png";
import ccc from "../../../assets/ccc.jpg";
import pdp from "../../../assets/pdp.jpg";
import spokenEnglish from "../../../assets/spokenenglish.jpg";

const courses = [
  {
    id: 1,
    title: "Social Media Marketing",
    description:
      "Become a social media expert with our Social Media Management & Marketing course. You'll learn how to create effective content strategies, engage audiences, and grow your presence on platforms like Facebook, Instagram, LinkedIn, and Twitter. This course covers both organic and paid marketing techniques, including analytics and advertising. Perfect for entrepreneurs, marketers, or anyone looking to enhance their digital skills.",
    image: smm, // Direct reference to the imported image
    link: "/training/social-media-marketing",
  },
  {
    id: 2,
    title: "Graphic Designing",
    description:
      "Master the art of visual communication with our Graphic Designing course. You'll explore tools like Adobe Photoshop, Illustrator, and Canva, learning how to create eye-catching designs for web, print, and social media. This course covers essential principles such as typography, color theory, and layout design. Whether you're designing logos, social media posts, or marketing materials, this course will unleash your creativity.",
    image: gd,
    link: "/courses/graphic-design",
  },
  {
    id: 3,
    title: "Web Development",
    description:
      "Our Web Development course equips you with the skills to build dynamic, responsive websites from scratch. You'll learn both front-end and back-end technologies, including HTML, CSS, JavaScript, and frameworks like React and Node.js. Whether you're a beginner or looking to expand your web skills, this course will guide you through every step. By the end, you'll be able to create professional websites and web applications.",
    image: webdev,
    link: "/courses/web-development",
  },
  {
    id: 4,
    title: "Computer Courses",
    description:
      "Our Basic to Advanced Computer Course is made to help you understand computers from the very basics to more advanced skills. In the basic part, you'll learn how to use a computer, manage files, browse the internet, and work with popular software like Microsoft Word, Excel, and PowerPoint. As you move to the advanced level, you'll get into topics like using cloud storage, learning advanced Excel features like formulas, charts, and pivot tables, and improving your computer’s performance. This course is perfect for both beginners and those who want to boost their computer skills in today’s tech-driven world.",
    image: ccc,
    link: "/courses/computer-basics",
  },
  {
    id: 4,
    title: "Personality Development Program",
    description:
      "Our Personality Development Program (PDP) is designed to help you enhance your communication skills, boost your confidence, and develop a positive attitude towards life and work. Through interactive sessions, practical exercises, and expert guidance, you’ll learn effective public speaking, leadership skills, time management, and how to handle stress. Whether you’re a student, working professional, or entrepreneur, this course equips you with the tools needed to succeed in both personal and professional settings. Elevate your personality, improve your interpersonal skills, and unlock your true potential with our PDP course.",
    image: pdp,
    link: "/courses/personality-development-program",
  },
  {
    id: 4,
    title: "Spoken English",
    description:
      "Our Spoken English course is designed to help you speak English confidently and fluently. Whether you’re a beginner or want to improve your skills, this course covers everything from basic grammar and vocabulary to advanced speaking techniques. You’ll practice speaking, listening, and pronunciation through interactive lessons and real-life conversations. Perfect for students, professionals, or anyone looking to improve their English communication skills in everyday life",
    image: spokenEnglish,
    link: "/courses/spoken-english-courses",
  },
];

const CoursesPage = () => {
  return (
    <div className="container mx-auto p-6">
      {/* <h1 className="text-4xl font-bold text-center mb-10">Our Courses</h1> */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {courses.map((course) => (
          <div
            key={course.id}
            className="max-w-md rounded overflow-hidden shadow-lg"
          >
            <img
              className="w-full h-80 object-cover"
              src={course.image} // No curly braces, just direct reference
              alt={course.title}
            />
            <div className="px-6 py-4">
              <h2 className="font-bold mb-2 text-[1.5rem] font-crimson text-orange-400">{course.title}</h2>
              <p className="text-gray-700 text-base text-[1.25rem] font-crimson">{course.description}</p>
            </div>
            <div className="px-6 pt-4 pb-2">
              <Link
                to={course.link}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded font-crimson"
              >
                Learn More
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CoursesPage;
