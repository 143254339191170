import React, { useRef } from "react";
import star from "../../assets/download-removebg-preview (2).png";
const Testimonials = () => {
  const scrollRef = useRef(null);

  const scroll = (direction) => {
    const { current } = scrollRef;
    const scrollAmount = 330; // Width of a single card (including margin)

    if (direction === "left") {
      current.scrollLeft -= scrollAmount;
    } else {
      current.scrollLeft += scrollAmount;
    }
  };

  return (
    <section id="testimonials" className="py-16 bg-gray-100">
      <div className="text-center mb-12">
        <h2 className="text-4xl font-bold">Our Happy Faces</h2>
        <p className="text-gray-600 mt-4">
          We value our clients and their success stories.
        </p>
      </div>

      {/* Carousel Container */}
      <div className="relative container mx-auto px-4">
        {/* Left Arrow */}
        <button
          className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white rounded-full p-2 z-10 hover:bg-gray-700"
          onClick={() => scroll("left")}
        >
          ◄
        </button>

        {/* Testimonials */}
        <div
          ref={scrollRef}
          className="flex overflow-x-scroll scrollbar-hide space-x-8 scroll-smooth"
        >
          {/* Testimonial 1 */}
          <div className="bg-white p-6 min-w-[330px] rounded-lg shadow-lg hover:shadow-2xl transition duration-300">
            <div className="flex items-center mb-4">
              <img
                className="w-12 h-12 rounded-full mr-4"
                src={star}
                alt="Client 1"
              />
              <div>
                <h3 className="text-lg font-semibold">Puri Electronics</h3>
                <p className="text-sm text-gray-500">
                  Owner of Puri Electronics
                </p>
              </div>
            </div>
            <p className="text-gray-700">
              "Ambey Nexus helped us transform our online presence with their
              outstanding digital marketing services. Our social media
              engagement skyrocketed, and their SEO strategies boosted our
              website ranking significantly. Highly recommend for anyone looking
              to grow their business online!
            </p>
          </div>

          {/* Testimonial 2 */}
          <div className="bg-white p-6 min-w-[330px] rounded-lg shadow-lg hover:shadow-2xl transition duration-300">
            <div className="flex items-center mb-4">
              <img
                className="w-12 h-12 rounded-full mr-4"
                src={star}
                alt="Client 2"
              />
              <div>
                <h3 className="text-lg font-semibold">Anjali</h3>
                <p className="text-sm text-gray-500">Student of Bca</p>
              </div>
            </div>
            <p className="text-gray-700">
              "I enrolled in the Computer Training Course at Ambey Nexus and
              couldn't be happier. The instructors were knowledgeable, and the
              course took me from a beginner to confidently using advanced
              features in popular software like Excel and PowerPoint. A great
              learning experience!"
            </p>
          </div>

          {/* Testimonial 3 */}
          <div className="bg-white p-6 min-w-[330px] rounded-lg shadow-lg hover:shadow-2xl transition duration-300">
            <div className="flex items-center mb-4">
              <img
                className="w-12 h-12 rounded-full mr-4"
                src={star}
                alt="Client 3"
              />
              <div>
                <h3 className="text-lg font-semibold">Gaurav Saini</h3>
                <p className="text-sm text-gray-500">
                  Student of Digital Marketing
                </p>
              </div>
            </div>
            <p className="text-gray-700">
              Ambey Nexus has been a game-changer for me as a student. I
              enrolled in their computer training course and gained practical
              skills that I can apply in my studies and future career. The
              instructors were supportive, and the learning environment was
              great. Their guidance on my school project was also incredibly
              helpful. Highly recommended!"
            </p>
          </div>
          <div className="bg-white p-6 min-w-[330px] rounded-lg shadow-lg hover:shadow-2xl transition duration-300">
            <div className="flex items-center mb-4">
              <img
                className="w-12 h-12 rounded-full mr-4"
                src={star}
                alt="Client 3"
              />
              <div>
                <h3 className="text-lg font-semibold">Surinder</h3>
                <p className="text-sm text-gray-500">Client</p>
              </div>
            </div>
            <p className="text-gray-700">
              The team at Ambey Nexus made the process of applying for my PAN
              card and updating my Aadhaar seamless. Their digital solution
              center services are quick, reliable, and very user-friendly. I
              felt supported throughout the entire process.
            </p>
          </div>
          <div className="bg-white p-6 min-w-[330px] rounded-lg shadow-lg hover:shadow-2xl transition duration-300">
            <div className="flex items-center mb-4">
              <img
                className="w-12 h-12 rounded-full mr-4"
                src={star}
                alt="Client 3"
              />
              <div>
                <h3 className="text-lg font-semibold">Rahul</h3>
                <p className="text-sm text-gray-500">Student</p>
              </div>
            </div>
            <p className="text-gray-700">
              I’ve used Ambey Nexus for various typing and form-filling
              services, and the experience has always been smooth. Whether it's
              English, Hindi, or Punjabi, their accuracy and speed are
              unmatched. Their commitment to customer satisfaction really sets
              them apart.
            </p>
          </div>

          {/* Add more testimonials if needed */}
        </div>

        {/* Right Arrow */}
        <button
          className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white rounded-full p-2 z-10 hover:bg-gray-700"
          onClick={() => scroll("right")}
        >
          ►
        </button>
      </div>
    </section>
  );
};

export default Testimonials;
