import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const computerCourses = [
  {
    id: 1,
    title: "Basic Computer Skills",
    overview: "Learn the fundamental skills necessary for operating a computer, including file management and internet browsing.",
    keyModules: [
      "1. Understanding Computer Hardware and Software",
      "2. Operating System Basics: Windows and Mac",
      "3. File Management: Creating and Organizing Files",
      "4. Using the Internet: Browsing and Research",
      "5. Introduction to Microsoft Office: Word and Excel",
      "6. Basic Troubleshooting Techniques",
      "7. Final Project: Create a Personal Computer Use Plan",
    ],
    duration: "45 Days",
    price: "₹8,000",
    certification: "Available upon completion",
  },
  {
    id: 2,
    title: "Microsoft Office Mastery",
    overview: "Become proficient in Microsoft Office applications including Word, Excel, PowerPoint, and Outlook.",
    keyModules: [
      "1. Microsoft Word: Document Creation and Formatting",
      "2. Microsoft Excel: Data Analysis and Charting",
      "3. Microsoft PowerPoint: Creating Engaging Presentations",
      "4. Microsoft Outlook: Email Management and Scheduling",
      "5. Advanced Excel: Formulas, Pivot Tables, and Macros",
      "6. Final Project: Create a Comprehensive Office Document",
    ],
    duration: "30 Days",
    price: "₹12,000",
    certification: "Available upon completion",
  },
  {
    id: 3,
    title: "Intermediate Computer Networking",
    overview: "Understand the fundamentals of computer networking, including configuration and troubleshooting.",
    keyModules: [
      "1. Introduction to Networking Concepts",
      "2. Network Types: LAN, WAN, and WLAN",
      "3. IP Addressing and Subnetting",
      "4. Basic Network Hardware: Routers and Switches",
      "5. Network Configuration and Management",
      "6. Troubleshooting Network Issues",
      "7. Final Project: Design a Small Office Network",
    ],
    duration: "45 Days",
    price: "₹15,000",
    certification: "Available upon completion",
  },
  {
    id: 4,
    title: "Advanced Networking Techniques",
    overview: "Dive deeper into advanced networking concepts, protocols, and configurations.",
    keyModules: [
      "1. Advanced IP Addressing and Routing Protocols",
      "2. Network Security Basics",
      "3. Wireless Networking and Security",
      "4. Configuring Virtual Private Networks (VPNs)",
      "5. Implementing Firewall Technologies",
      "6. Network Monitoring and Performance Tuning",
      "7. Final Project: Develop a Secure Network Infrastructure",
    ],
    duration: "60 Days",
    price: "₹15,000",
    certification: "Available upon completion",
  },
  {
    id: 5,
    title: "Computer Maintenance and Troubleshooting",
    overview: "Learn how to maintain and troubleshoot computers and peripherals effectively.",
    keyModules: [
      "1. Computer Hardware Components and Their Functions",
      "2. Preventive Maintenance Techniques",
      "3. Identifying and Fixing Common Software Issues",
      "4. Virus and Malware Protection Strategies",
      "5. Data Backup and Recovery Techniques",
      "6. Final Project: Create a Maintenance Checklist for Personal Computers",
     ],
    duration: "30 Days",
    price: "₹10,000",
    certification: "Available upon completion",
  },
  {
    id: 6,
    title: "Data Management and Analysis",
    overview: "Master data management techniques and learn how to analyze data effectively.",
    keyModules: [
      "1. Introduction to Data Management Concepts",
      "2. Data Entry Techniques Using Excel",
      "3. Using Formulas for Data Analysis",
      "4. Introduction to Data Visualization Tools",
      "5. Creating Reports and Dashboards",
      "6. Final Project: Analyze and Present Data Using Excel",
     ],
    duration: "45 Days",
    price: "₹15,000",
    certification: "Available upon completion",
  },
];

const ComputerCourseCard = ({ course }) => (
  <motion.div
    className="max-w-md rounded overflow-hidden shadow-lg bg-white p-6"
    initial={{ y: 50, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    transition={{ duration: 0.8 }}
    whileHover={{ scale: 1.05 }}
  >
    <div className="px-6 py-4">
      <h2 className="font-bold mb-2 text-blue-600 text-[1.56rem] font-crimson">{course.title}</h2>
      <p className="text-gray-700 text-base text-[1.25rem] font-crimson">{course.overview}</p>
      <h3 className="font-semibold mt-4 text-[1.5rem] font-crimson">Key Modules:</h3>
      <ul className="list-disc list-inside text-gray-700">
        {course.keyModules.map((module, index) => (
          <ul className="text-[1.25rem] font-crimson" key={index}>{module}</ul>
        ))}
      </ul>
      <p className="text-gray-900 font-semibold mt-4 text-[1.25rem] font-crimson">Duration: {course.duration}</p>
      <p className="text-gray-900 font-semibold text-[1.25rem] font-crimson">Price: {course.price}</p>
      <p className="text-gray-600 text-[1.25rem] font-crimson">Certification: {course.certification}</p>
    </div>
    <div className="px-6 pt-4 pb-2 text-[1.25rem] font-crimson">
      <Link
        to={`/contact`}
        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded block text-center mt-4"
      >
        Contact Us To Know More
      </Link>
    </div>
  </motion.div>
);

const ComputerBasics = () => {
  return (
    <div className="bg-gradient-to-r from-blue-100 to-blue-300 p-8 min-h-screen">
      <h1 className="text-[3rem] font-crimson font-bold text-center text-orange-600 mb-12">
        Computer Courses
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {computerCourses.map(course => (
          <ComputerCourseCard key={course.id} course={course} />
        ))}
      </div>
    </div>
  );
};

export default ComputerBasics;
