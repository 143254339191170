import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto p-6">
      <h1 className="text-4xl font-bold text-center mb-8 text-[2rem] font-crimson">Privacy Policy</h1>
      
      <p className="text-gray-700 mb-4 text-[1.56rem] font-crimson">
        {/* <strong>Effective Date:</strong> [Insert Date] */}
      </p>
      
      <h2 className="text-2xl font-semibold mb-4">Introduction</h2>
      <p className="text-gray-700 mb-4 text-[1.56rem] font-crimson">
        At <strong>Ambey Nexus</strong>, we take your privacy seriously. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you interact with our website and services. By using our website, you agree to the terms and practices described in this policy.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-[1.56rem] font-crimson">Information We Collect</h2>
      <p className="text-gray-700 mb-4 text-[1.56rem] font-crimson">
        We collect various types of information to provide and improve our services, including:
      </p>
      <ul className="list-disc list-inside mb-4 text-[1.56rem] font-crimson">
        <li><strong>Personal Information:</strong> Name, email address, phone number, and other identifying information you voluntarily provide.</li>
        <li><strong>Technical Information:</strong> Information such as IP address, browser type, operating system, and device information.</li>
        <li><strong>Usage Data:</strong> Information about how you navigate and use our website through cookies and similar technologies.</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-4 text-[1.56rem] font-crimson">How We Use Your Information</h2>
      <p className="text-gray-700 mb-4">
        We use the information we collect for several purposes, including:
      </p>
      <ul className="list-disc list-inside mb-4 text-[1.56rem] font-crimson">
        <li>Providing our services such as training courses and digital marketing solutions.</li>
        <li>Improving user experience and personalizing content.</li>
        <li>Processing transactions and managing payments.</li>
        <li>Sending updates, marketing communications, or other relevant information.</li>
        <li>Analyzing usage trends and monitoring website performance.</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-4 text-[1.56rem] font-crimson">Sharing Your Information</h2>
      <p className="text-gray-700 mb-4">
        We do not sell or rent your personal information. However, we may share your information under the following circumstances:
      </p>
      <ul className="list-disc list-inside mb-4 text-[1.56rem] font-crimson">
        <li><strong>Service Providers:</strong> We may share your information with trusted third-party service providers.</li>
        <li><strong>Legal Requirements:</strong> We may disclose information to comply with legal obligations.</li>
        <li><strong>Business Transfers:</strong> In case of a merger or acquisition, your information may be transferred.</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-4 text-[1.56rem] font-crimson">Cookies and Tracking Technologies</h2>
      <p className="text-gray-700 mb-4">
        We use cookies and similar technologies to enhance your experience. Cookies help us remember your preferences, analyze traffic, and deliver personalized content.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-[1.56rem] font-crimson">Data Security</h2>
      <p className="text-gray-700 mb-4 text-[1.56rem] font-crimson">
        We prioritize the security of your personal information and take appropriate measures to safeguard it. However, no method of transmission or storage is completely secure.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-[1.56rem] font-crimson">Your Data Protection Rights</h2>
      <p className="text-gray-700 mb-4 text-[1.56rem] font-crimson">
        Depending on your location, you may have rights regarding your personal information, including access, update, or deletion. Contact us if you wish to exercise any of these rights.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-[1.56rem] font-crimson">Third-Party Links</h2>
      <p className="text-gray-700 mb-4 text-[1.56rem] font-crimson">
        Our website may contain links to third-party websites. We are not responsible for the privacy practices of these sites.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-[1.56rem] font-crimson">Changes to This Privacy Policy</h2>
      <p className="text-gray-700 mb-4 text-[1.56rem] font-crimson">
        We may update this Privacy Policy from time to time. Any changes will be posted here, and your continued use of the site signifies your acceptance.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-[1.56rem] font-crimson">Contact Us</h2>
      <p className="text-gray-700 mb-4 text-[1.56rem] font-crimson">
        If you have any questions about this Privacy Policy, please contact us at:
      </p>
      <p className="text-gray-700 mb-4 text-[1.56rem] font-crimson">
        <strong>Ambey Nexus</strong> <br />
        Opp. Nayyar Colony Bhakra Road Nangal 140124  <br />
        info@ambeynexus.com <br />
         +91-7696637927
      </p>
    </div>
  );
};

export default PrivacyPolicy;
