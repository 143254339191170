import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const pdpCourse = {
  title: "PDP (Personality Development Program) Course",
  description: "Enhance your communication, leadership, and personal skills.",
  price: "₹10,000",
  duration: "30 Days",
  modules: [
    "Effective Communication Skills",
    "Public Speaking & Presentation",
    "Body Language & Confidence Building",
    "Leadership Skills",
    "Time Management & Goal Setting",
    "Team Building & Conflict Resolution",
    "Interview Preparation",
    "Final Assessment & Practical Session"
  ]
};

const PDPCoursePage = () => {
  return (
    <div className="bg-gradient-to-r from-green-100 to-blue-300 p-8 min-h-screen text-[2rem] font-crimson">
      <motion.h1
        className="text-5xl font-bold text-center text-orange-600 mb-12"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        {pdpCourse.title}
      </motion.h1>

      <div className="max-w-4xl mx-auto bg-white p-6 rounded-lg shadow-lg">
        <p className="text-gray-700 mb-4 ftext-[1.25rem] font-crimson">{pdpCourse.description}</p>
        <p className="text-gray-900 font-bold text-[1.25rem] font-crimson">Price: {pdpCourse.price}</p>
        <p className="text-gray-600 mb-4 text-[1.25rem] font-crimson">Duration: {pdpCourse.duration}</p>

        <h3 className="font-semibold text-xl mb-4 text-[2rem] font-crimson">Course Modules:</h3>
        <ul className="list-disc list-inside mb-6">
          {pdpCourse.modules.map((module, index) => (
            <li key={index} className="text-gray-700 text-[1.25rem] font-crimson">
              {module}
            </li>
          ))}
        </ul>

        <Link
          to="/contact"
          className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded block text-center"
        >
          Contact Us to Enroll
        </Link>
      </div>
    </div>
  );
};

export default PDPCoursePage;
