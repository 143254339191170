import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const graphicCourses = [
  {
    id: 1,
    title: "Graphic Designing Course",
    overview: "Master the art of visual communication through our Graphic Designing course.",
    keyModules: [
      "1. Introduction to Graphic Design: Learn the fundamentals.",
      "2. Adobe Photoshop: Master photo editing and manipulation.",
      "3. Adobe Illustrator: Create stunning vector graphics.",
      "4. Color Theory: Understand color palettes and combinations.",
      "5. Typography: Explore font selection and text layouts.",
      "6. Layout Design: Design effective compositions for print and web.",
      "7. Branding: Learn to create logos and brand identities.",
      "8. Final Project: Develop a comprehensive graphic design portfolio.",
    ],
    duration: "45 Days",
    price: "₹18,000",
    certification: "Available upon completion",
  },
  // Additional courses can be added here
  {
    id: 2,
    title: "UI/UX Design Course",
    overview: "Understand user experience and design intuitive interfaces.",
    keyModules: [
      "1. Introduction to UI/UX: Learn the basics of user interface and experience.",
      "2. Wireframing: Create blueprints for your designs.",
      "3. Prototyping: Develop interactive prototypes.",
      "4. User Research: Understand user needs and behaviors.",
      "5. Usability Testing: Validate designs through user feedback.",
      "6. Design Tools: Familiarize with Figma and Sketch.",
      "7. Responsive Design: Create designs for multiple devices.",
      "8. Final Project: Design a user-friendly application.",
    ],
    duration: "45 Days",
    price: "₹20,000",
    certification: "Available upon completion",
  },
  {
    id: 3,
    title: "Animation and Motion Graphics",
    overview: "Learn to create engaging animations and motion graphics.",
    keyModules: [
      "1. Introduction to Animation: Understand animation principles.",
      "2. Adobe After Effects: Master motion graphics techniques.",
      "3. 2D Animation: Create animations for storytelling.",
      "4. 3D Animation Basics: Introduction to 3D design and animation.",
      "5. Character Design: Learn to create animated characters.",
      "6. Video Editing: Enhance animations with video editing skills.",
      "7. Sound Design: Incorporate sound effects and music.",
      "8. Final Project: Create an animated short film.",
    ],
    duration: "45 Days",
    price: "₹22,000",
    certification: "Available upon completion",
  },
  {
    id: 4,
    title: "Digital Illustration Course",
    overview: "Learn digital illustration techniques for creative expression.",
    keyModules: [
      "1. Introduction to Digital Illustration: Basics of digital art.",
      "2. Drawing Techniques: Learn fundamental drawing skills.",
      "3. Illustration Tools: Master tools like Procreate and Adobe Fresco.",
      "4. Character Development: Create original character designs.",
      "5. Storytelling: Use illustrations to tell compelling stories.",
      "6. Color and Composition: Enhance illustrations with effective design.",
      "7. Portfolio Development: Create a portfolio of your best work.",
      "8. Final Project: Illustrate a children’s book or graphic novel.",
    ],
    duration: "45 Days",
    price: "₹19,000",
    certification: "Available upon completion",
  },
];

const GraphicDesignCourseCard = ({ course }) => (
  <motion.div
    className="max-w-sm rounded overflow-hidden shadow-lg bg-white p-6"
    initial={{ y: 50, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    transition={{ duration: 0.8 }}
    whileHover={{ scale: 1.05 }}
  >
    <div className="px-6 py-4">
      <h2 className="font-bold text-blue-500 mb-2 text-[1.5rem] font-crimson">{course.title}</h2>
      <p className="text-gray-700 text-base text-[1.25rem] font-crimson">{course.overview}</p>
      <h3 className="font-semibold mt-4 text-[1.25rem] font-crimson">Key Modules:</h3>
      <ul className="list-disc list-inside text-gray-700">
        {course.keyModules.map((module, index) => (
          <ul className="text-[1.25rem] font-crimson" key={index}>{module}</ul>
        ))}
      </ul>
      <p className="text-gray-900 font-semibold mt-4 text-[1.25rem] font-crimson">Duration: {course.duration}</p>
      <p className="text-gray-900 font-semibold text-[1.25rem] font-crimson">Price: {course.price}</p>
      <p className="text-gray-600 text-[1.25rem] font-crimson">Certification: {course.certification}</p>
    </div>
    <div className="px-6 pt-4 pb-2 text-[1.25rem] font-crimson">
      <Link
        to={`/contact`}
        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded block text-center mt-4"
      >
        Contact Us To Know More
      </Link>
    </div>
  </motion.div>
);

const Gd = () => {
  return (
    <div className="bg-gradient-to-r from-blue-100 to-blue-300 p-8 min-h-screen">
      <h1 className="text-5xl font-bold text-center text-orange-600 mb-12 text-[2rem] font-crimson">
        Graphic Designing Courses
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {graphicCourses.map(course => (
          <GraphicDesignCourseCard key={course.id} course={course} />
        ))}
      </div>
    </div>
  );
};

export default Gd;