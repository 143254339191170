import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const webDevelopmentCourses = [
  {
    id: 1,
    title: "Web Development Course",
    overview: "Learn the basics of web development, including front-end and back-end technologies.",
    keyModules: [
      "1. Introduction to Web Development: Understand the fundamentals.",
      "2. HTML: Structure web content with HTML.",
      "3. CSS: Style web pages with CSS for a polished look.",
      "4. JavaScript: Make web pages interactive with JavaScript.",
      "5. Responsive Design: Create designs that work on all devices.",
      "6. Introduction to Bootstrap: Use Bootstrap to speed up your development.",
      "7. Basic SEO: Learn the fundamentals of search engine optimization.",
      "8. Final Project: Develop a fully functional website.",
    ],
    duration: "45 Days",
    price: "₹20,000",
    certification: "Available upon completion",
  },
  // Additional courses can be added here
  {
    id: 2,
    title: "Advanced Web Development",
    overview: "Dive deeper into advanced web development techniques and frameworks.",
    keyModules: [
      "1. JavaScript ES6: Explore the latest JavaScript features.",
      "2. Front-end Frameworks: Learn React and Angular.",
      "3. Node.js: Understand back-end development with Node.js.",
      "4. Databases: Introduction to NoSQL and SQL databases.",
      "5. API Development: Create and consume APIs.",
      "6. Version Control with Git: Manage code changes efficiently.",
      "7. Deployment: Learn how to deploy web applications.",
      "8. Final Project: Build a complete web application.",
    ],
    duration: "60 Days",
    price: "₹30,000",
    certification: "Available upon completion",
  },
  {
    id: 3,
    title: "Full-Stack Web Development",
    overview: "Become a full-stack developer with skills in both front-end and back-end technologies.",
    keyModules: [
      "1. HTML & CSS: Master web structure and styling.",
      "2. JavaScript Basics: Understand the core concepts of JavaScript.",
      "3. React: Build dynamic user interfaces with React.",
      "4. Node.js & Express: Develop server-side applications.",
      "5. MongoDB: Work with NoSQL databases for data storage.",
      "6. RESTful APIs: Learn how to create and use APIs.",
      "7. Deployment & Hosting: Understand how to host your applications.",
      "8. Final Project: Develop a full-stack application.",
    ],
    duration: "90 Days",
    price: "₹25,000",
    certification: "Available upon completion",
  },
  {
    id: 4,
    title: "Web Design Fundamentals",
    overview: "Focus on the principles of web design to create beautiful and user-friendly websites.",
    keyModules: [
      "1. Principles of Design: Learn design fundamentals.",
      "2. Color Theory: Understand the use of color in design.",
      "3. Typography: Explore font selection and readability.",
      "4. User Experience (UX): Learn to design for user satisfaction.",
      "5. Wireframing: Create wireframes for your designs.",
      "6. Prototyping: Develop prototypes to visualize design.",
      "7. Usability Testing: Validate designs with real users.",
      "8. Final Project: Create a portfolio of web design projects.",
    ],
    duration: "45 Days",
    price: "₹20,000",
    certification: "Available upon completion",
  },
];

const WebDevelopmentCourseCard = ({ course }) => (
  <motion.div
    className="max-w-sm rounded overflow-hidden shadow-lg bg-white p-6"
    initial={{ y: 50, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    transition={{ duration: 0.8 }}
    whileHover={{ scale: 1.05 }}
  >
    <div className="px-6 py-4">
      <h2 className="font-bold mb-2 text-blue-600 text-[2rem] font-crimson">{course.title}</h2>
      <p className="text-gray-700 text-base text-[1.25rem] font-crimson">{course.overview}</p>
      <h3 className="font-semibold mt-4 text-[1.5rem] font-crimson">Key Modules:</h3>
      <ul className="list-disc list-inside text-gray-700">
        {course.keyModules.map((module, index) => (
          <ul className="text-[1.2rem] font-crimson" key={index}>{module}</ul>
        ))}
      </ul>
      <p className="text-gray-900 font-semibold mt-4 text-[1.25rem] font-crimson">Duration: {course.duration}</p>
      <p className="text-gray-900 font-semibold text-[1.25rem] font-crimson">Price: {course.price}</p>
      <p className="text-gray-600 text-[1.25rem] font-crimson">Certification: {course.certification}</p>
    </div>
    <div className="px-6 pt-4 pb-2">
      <Link
        to={`/contact`}
        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded block text-center mt-4 "
      >
        Contact Us To Know More
      </Link>
    </div>
  </motion.div>
);

const WebDev = () => {
  return (
    <div className="bg-gradient-to-r from-blue-100 to-blue-300 p-8 min-h-screen">
      <h1 className="text-5xl font-bold text-center text-orange-600 mb-12 text-[2rem] font-crimson">
        Web Development Courses
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {webDevelopmentCourses.map(course => (
          <WebDevelopmentCourseCard key={course.id} course={course} />
        ))}
      </div>
    </div>
  );
};

export default WebDev;
