import React from "react";
import CoursesPage from "./CoursesPage";

const Training = () => {
  return (
    <>
      {/* <h1>Coming from training section</h1> */}
      {/* all training data and traiining page module comming from services folder for refrences check out there */}
      <CoursesPage />
    </>
  );
};

export default Training;
