import React from "react";
// import homeBgImg from "../../assets/homebg.jpg";
import LandingPage from "./LandingPage";
import Testimonials from "./Testimonials";
import Newsletter from "./NewsLater";

const HomePage = () => {
  return (
    <>
      <LandingPage />
      <Testimonials />
      <Newsletter />
    </>
  );
};

export default HomePage;
