import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const courses = [
  {
    id: 1,
    title: "Social Media Marketing Course",
    overview: "Learn to harness social media for brand growth and audience engagement.",
    keyModules: [
      "1. Introduction to Social Media: Explore platforms and current trends.",
      "2. Content Strategy: Develop effective content and calendars.",
      "3. Platform Strategies: Master Facebook, Instagram, LinkedIn, Twitter, and TikTok.",
      "4. Audience Engagement: Build community and manage brand reputation.",
      "5. Analytics & Performance: Measure ROI and optimize campaigns.",
      "6. Paid Advertising: Set up and manage ad campaigns.",
      "7. Legal Considerations: Understand regulations and privacy.",
      "8. Final Project: Create a comprehensive marketing plan.",
    ],
    duration: "45 Days",
    price: "₹15,000",
    certification: "Available upon completion",
  },
  // Add more course objects here for additional cards
  {
    id: 2,
    title: "Search Engine Optimization (SEO)",
    overview: "Optimize your website to rank higher on search engines and increase organic traffic.",
    keyModules: [
      "1. SEO Fundamentals: Understand keywords and search intent.",
      "2. On-Page SEO: Optimize content and meta tags.",
      "3. Off-Page SEO: Build backlinks and improve site authority.",
      "4. Technical SEO: Enhance website performance and indexability.",
      "5. Analytics & Reporting: Use tools to track and measure SEO success.",
      "6. SEO Tools: Familiarize with tools like Google Analytics and SEMrush.",
      "7. Local SEO: Optimize for local search visibility.",
      "8. Final Project: Create an SEO strategy for a real website.",
    ],
    duration: "45 Days",
    price: "₹12,000",
    certification: "Available upon completion",
  },
  {
    id: 3,
    title: "Email Marketing",
    overview: "Create compelling email campaigns and nurture leads effectively.",
    keyModules: [
      "1. Email Marketing Basics: Understand email marketing concepts.",
      "2. Building a Mailing List: Strategies for list growth.",
      "3. Email Design: Best practices for effective email layouts.",
      "4. Segmentation & Targeting: Personalize your campaigns.",
      "5. Automation: Set up automated email sequences.",
      "6. Analytics: Measure open rates, CTR, and conversions.",
      "7. Compliance: Understand email regulations (GDPR, CAN-SPAM).",
      "8. Final Project: Develop an email marketing campaign.",
    ],
    duration: "45 Days",
    price: "₹10,000",
    certification: "Available upon completion",
  },
  {
    id: 4,
    title: "Content Marketing",
    overview: "Develop strategies to create and distribute valuable content.",
    keyModules: [
      "1. Introduction to Content Marketing: Understanding the basics.",
      "2. Content Strategy: Planning and setting objectives.",
      "3. Content Creation: Writing blogs, articles, and multimedia.",
      "4. Distribution Channels: Use social media, email, and SEO.",
      "5.Measuring Success: KPIs and analytics for content marketing.",
      "6.Content Curation: Strategies to share existing content.",
      "7.Brand Storytelling: Engaging audiences with narratives.",
      "8.Final Project: Create a content marketing plan for a brand.",
    ],
    duration: "45 Days",
    price: "₹13,500",
    certification: "Available upon completion",
  },
];

const CourseCard = ({ course }) => (
  <motion.div
    className="max-w-sm rounded overflow-hidden shadow-lg bg-white p-6"
    initial={{ y: 50, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    transition={{ duration: 0.8 }}
    whileHover={{ scale: 1.05 }}
  >
    <div className="px-6 py-4">
      <h2 className="font-bold mb-2 text-blue-600 text-[1.5rem] font-crimson">{course.title}</h2>
      <p className="text-gray-700 text-[1.2rem] font-crimson">{course.overview}</p>
      <h3 className="font-semibold mt-4">Key Modules:</h3>
      <ul className="list-disc list-inside text-gray-700">
        {course.keyModules.map((module, index) => (
          <ul key={index} className="text-[1.3rem] font-crimson">{module}</ul>
        ))}
      </ul>
      <p className="text-gray-900 font-semibold mt-4 text-[1.5rem] font-crimson">Duration: {course.duration}</p>
      <p className="text-gray-900 font-semibold text-[1.5rem] font-crimson">Price: {course.price}</p>
      <p className="text-gray-600 text-[1.5rem] font-crimson">Certification: {course.certification}</p>
    </div>
    <div className="px-6 pt-4 pb-2">
      <Link
        to={`/contact`}
        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded block text-center mt-4 text-[1.2rem] font-crimson"
      >
        Contact Us To Know More
      </Link>
    </div>
  </motion.div>
);

const DigitalMarketingCourses = () => {
  return (
    <div className="bg-gradient-to-r from-blue-100 to-blue-300 p-8 min-h-screen">
      <h1 className="text-5xl font-bold text-center text-orange-600 mb-12 text-[2rem] font-crimson">
        Digital Marketing Courses
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {courses.map(course => (
          <CourseCard key={course.id} course={course} />
        ))}
      </div>
    </div>
  );
};

export default DigitalMarketingCourses;
