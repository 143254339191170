import React, { useState } from "react";
import "./tabs.css";
import ServicesCard from "./Cards";
import GeneralServiceCards from "./GeneralServiceCards";
import { Link } from "react-router-dom";
import numeroImg from "../../../assets/numerology.jpg";

const Tabs = ({ image, title, description, link }) => {
  const [activeTab, setActiveTab] = useState("webdev");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="tabs-container">
      {/* Tab Buttons */}
      <div className="flex justify-center space-x-4 p-4">
        <button
          className={`tab-button ${activeTab === "webdev" ? "active" : ""} font-crimson font-bold text-[1.5rem]`}
          onClick={() => handleTabClick("webdev")}
        >
          IT Services
        </button>
        <button
          className={`tab-button ${activeTab === "gd" ? "active" : ""} font-crimson font-bold text-[1.5rem]`}
          onClick={() => handleTabClick("gd")}
        >
          Online Digital Services
        </button>
        <button
          className={`tab-button ${activeTab === "smm" ? "active" : ""} font-crimson font-bold text-[1.5rem]`}
          onClick={() => handleTabClick("smm")}
        >
          Numero And Vastu
        </button>
      </div>

      {/* Tab Content */}
      <div className="p-4">
        {activeTab === "webdev" && (
          <div>
            <ServicesCard />
          </div>
        )}
        {activeTab === "gd" && (
          <div>
            <GeneralServiceCards />
          </div>
        )}
        {activeTab === "smm" && (
          <div>
            <h2 className="mt-4 bg-blue-600 text-white py-2 px-6 rounded-md text-center text-xl mx-auto block hover:bg-blue-700 transition duration-300 ease-in-out">Numerology</h2>
            <div className="bg-slate-200 text-black rounded-lg shadow-lg overflow-hidden transition-transform transform hover:cursor-pointer mt-10">
              <img
                src={numeroImg}
                alt={title}
                className="w-full h-[450px] object-fit "
              />
              <div className="p-4">
                <h3 className="text-lg font-semibold">
                  Unlock the Power of Numerology{" "}
                </h3>
                <p className="text-gray-600 mt-2 text-xl">
                  Numerology is an ancient practice that reveals the hidden
                  significance of numbers in your life. It’s based on the belief
                  that numbers, especially those tied to your birth date and
                  name, hold deep insights into your personality, destiny, and
                  life path. By analyzing these numbers, you can uncover your
                  strengths, weaknesses, and opportunities for growth. Whether
                  you're looking to find meaning in personal relationships,
                  career choices, or life decisions, numerology serves as a
                  guide to self-awareness and personal transformation.
                </p>
                <h3 className="text-lg font-semibold">
                  Discover Your Life Path Number
                </h3>
                <p className="text-gray-600 mt-2 text-xl">
                  One of the core elements of numerology is the Life Path
                  Number, which is derived from your birth date. This number
                  gives you a clearer understanding of your life’s purpose and
                  the direction you're meant to take. Each Life Path Number has
                  unique traits and tendencies, helping you to align with your
                  natural abilities. By knowing your Life Path Number, you can
                  make decisions that resonate with your true self, helping you
                  lead a more fulfilled and balanced life.
                </p>
                <h3 className="text-lg font-semibold ">
                  Numerology for Personal Relationships{" "}
                </h3>
                <p className="text-gray-600 mt-2 text-xl">
                  Numerology doesn’t just stop at self-discovery—it also plays a
                  powerful role in understanding your relationships with others.
                  Through a comparison of your numbers with those of your
                  partner, friends, or family members, numerology can offer
                  insights into compatibility, potential conflicts, and ways to
                  strengthen your bonds. This deeper understanding allows you to
                  navigate relationships with more empathy and awareness,
                  fostering healthier and more harmonious connections.
                </p>
                <h3 className="text-lg font-semibold">
                  Career and Business Guidance
                </h3>
                <p className="text-gray-600 mt-2 text-xl">
                  Are you feeling uncertain about your career path or business
                  decisions? Numerology can help guide you toward the right
                  choices by revealing the numbers most aligned with success in
                  your professional life. Certain numbers in your chart can
                  indicate strengths in leadership, creativity, communication,
                  or financial acumen. Whether you’re an entrepreneur seeking to
                  start a new venture or someone looking for a career shift,
                  numerology can highlight the path that best suits your
                  ambitions and talents.
                </p>
                <h3 className="text-lg font-semibold">
                  Numerology and Timing: The Power of Cycles
                </h3>
                <p className="text-gray-600 mt-2 text-xl">
                  Numerology also emphasizes the importance of timing. Known as
                  personal year cycles, these are periods in your life where
                  certain energies are more prominent, helping or hindering your
                  progress depending on your awareness of them. By understanding
                  these cycles, you can plan major life events—like starting a
                  business, moving, or making significant life changes—during
                  periods when you are most likely to succeed. Numerology gives
                  you the tools to act when the timing is right, maximizing your
                  potential for growth and success.
                </p>
              </div>
              <Link
                to="/contact"
                className="mt-4 bg-blue-600 text-white py-2 px-6 rounded-md text-center text-xl mx-auto block hover:bg-blue-700 transition duration-300 ease-in-out"
              >
                Contact Us Now
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Tabs;
