import React, { useEffect } from "react";
import "./AboutUs.css"; // You can include extra CSS if needed for advanced animations
import image from "../../assets/gallery-3.jpg";
import img2 from "../../assets/cta-img.jpg";
import star from "../../assets/download-removebg-preview (2).png";
import AOS from "aos";
import "aos/dist/aos.css";

const AboutUs = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <div className="about-us-page">
      {/* About Us Section */}
      <section className="py-16 bg-gray-100 m-auto">
        <div className="container mx-auto flex flex-col lg:flex-row items-center lg:space-x-12 px-4 lg:px-16">
          <div className="lg:w-1/2 order-2 lg:order-1">
            <h2 className="text-4xl font-bold mb-4 font-crimson text-orange-500">
              About Us
            </h2>

            <p className="text-gray-700 text-lg font-crimson font-extrabold text-[2rem] ">
              Your One-Stop Destination for Essential Solutions
            </p>
            <br />
            <p className="text-gray-700 text-lg font-crimson text-[1.6rem] font-semibold">
              Welcome to Ambey Nexus, your trusted hub for a wide array of
              essential services designed to empower you and meet your diverse
              needs. Our mission is to provide expert digital marketing
              solutions that help businesses grow their online presence and
              reach their target audience effectively. We also specialize in
              comprehensive banking services, ensuring that your financial
              management is seamless and efficient. At Ambey Nexus, we
              understand the importance of skill enhancement in today’s
              competitive landscape. That's why we offer a range of computer
              training courses tailored to equip you with the latest knowledge
              and expertise in technology. Additionally, our digital solutions
              simplify various processes, allowing you to apply for PAN cards,
              process passports, and access government job assistance with ease.
              With a commitment to excellence and customer satisfaction, we
              strive to simplify your tasks and help you achieve your goals
              efficiently. Trust Ambey Nexus to be your partner in navigating
              the complexities of modern life, as we work together to unlock new
              opportunities and foster growth.
            </p>
          </div>
          <div className="lg:w-1/2 mb-8 lg:mb-0 order-1 lg:order-2 m-auto">
            <img
              src={image}
              alt="About Us"
              className="shadow-lg h-[40rem] rounded"
            />
          </div>
        </div>
      </section>

      {/* Mission Section */}
      <section className="py-16 bg-white scroll-animation" data-aos="fade-up">
        <div className="container mx-auto flex flex-col lg:flex-row-reverse items-center lg:space-x-12 px-4 lg:px-16">
          <div className="lg:w-1/2">
            <h2 className="text-4xl font-bold mb-4 text-orange-500">
              Our Mission
            </h2>
            <p className="text-gray-700 text-lg font-crimson text-[1.6rem]">
              Our mission is to provide comprehensive, cutting-edge digital
              services that empower both individuals and businesses to thrive in
              today’s fast-paced digital world. We aim to simplify complex
              processes, from digital marketing strategies to government-related
              services, making them accessible to everyone. By offering
              personalized solutions, we ensure that our customers can achieve
              their personal and professional goals efficiently. We are
              committed to fostering skill development through our training
              programs, helping individuals stay ahead in the digital age.
              Ultimately, we strive to create lasting value by delivering
              reliable, customer-focused services that enhance productivity and
              success.
            </p>
          </div>
          <div className="lg:w-1/2 mb-8 lg:mb-0">
            <img
              src={star}
              alt="Mission"
              className="rounded-[50%] shadow-lg h-[300px]"
            />
          </div>
        </div>
      </section>

      {/* Vision Section */}
      <section
        className="py-16 bg-gray-100 scroll-animation"
        data-aos="fade-up"
      >
        <div className="container mx-auto flex flex-col lg:flex-row items-center lg:space-x-12 px-4 lg:px-16">
          <div className="lg:w-1/2 order-2 lg:order-1">
            <h2 className="text-4xl font-bold mb-4 text-orange-500">
              Our Vision
            </h2>
            <p className="text-black-700 text-lg font-crimson text-[1.6rem]">
              Our vision is to become a leading digital services provider,
              recognized for transforming lives and businesses through
              innovative solutions and exceptional support. We aspire to create
              a trusted platform where users can seamlessly access a wide range
              of services, from banking and digital marketing to online
              government services and education. By embracing technological
              advancements, we aim to stay ahead of industry trends, continually
              evolving to meet the changing needs of our customers. We envision
              a future where our solutions help bridge digital gaps and foster
              growth in both urban and rural communities. Through our commitment
              to excellence, we seek to be a driving force in the digital
              transformation of society.
            </p>
          </div>
          <div className="lg:w-1/2 mb-8 lg:mb-0 order-1 lg:order-2">
            <img
              src={star}
              alt="Vision"
              className="rounded-[50%] shadow-lg h-[300px]"
            />
          </div>
        </div>
      </section>

      {/* Core Values Section */}
      <section className="py-16 bg-white scroll-animation" data-aos="fade-up">
        <div className="container mx-auto flex flex-col lg:flex-row-reverse items-center lg:space-x-12 px-4 lg:px-16">
          <div className="lg:w-1/2">
            <h2 className="text-4xl font-bold mb-4 text-orange-500">
              Core Values
            </h2>
            <p className="text-black-700 text-lg font-crimson text-[1.6rem]">
              ➡️ Customer-Centricity: We prioritize customer satisfaction by
              offering personalized solutions that meet their unique needs.{" "}
              <br />
              ➡️Innovation: We continuously adopt the latest technology to
              provide the most efficient and effective services.
              <br />
              ➡️Integrity: We operate with honesty and transparency, building
              trust with every service we provide.
              <br />
              ➡️Quality: Excellence is at the core of everything we do, from our
              training programs to our digital marketing solutions.Keep learning
              and adapting to new technologies
              <br />
              ➡️Empowerment: We are committed to empowering individuals and
              businesses by providing accessible, comprehensive, and impactful
              services.
            </p>
          </div>
          <div className="lg:w-1/2 mb-8 lg:mb-0">
            <img
              src={star}
              alt="Core Values"
              className="rounded-[50%] shadow-lg h-[300px]"
            />
          </div>
        </div>
      </section>

      {/* Let's Grow Together Section */}
      <section
        className="py-16 bg-gray-100 scroll-animation"
        data-aos="fade-up"
      >
        <div className="container mx-auto flex flex-col lg:flex-row items-center lg:space-x-12 px-4 lg:px-16">
          <div className="lg:w-1/2 order-2 lg:order-1">
            <h2 className="text-4xl font-bold mb-4 text-orange-500">
              Have a something on mind?
            </h2>
            <p className="text-black-700 text-lg font-crimson text-[1.6rem]">
              We can help you bring your ideas to life. Let’s talk about what we
              can build and raise together.
            </p>
            <a
              href="/contact"
              className="inline-block bg-purple-600 text-white font-semibold px-6 py-3 mt-4 rounded-lg hover:bg-purple-700 transition duration-300"
            >
              Contact Us
            </a>
          </div>
          <div className="lg:w-1/2 mb-8 lg:mb-0 order-1 lg:order-2">
            <img
              src={img2}
              alt="Let's Grow Together"
              className="rounded-[50%] shadow-lg h-[250px]"
            />
          </div>
        </div>
        <div className="container mx-auto flex flex-col lg:flex-row items-center lg:space-x-12 px-4 lg:px-16">
          <h1 className="text-4xl font-bold mb-4 m-3">
            Let us together build a flourishing business
          </h1>
          <p className="text-gray-700 text-lg ml-8 font-crimson text-[1.6rem]">
            When connected with us, you aren’t growing your business alone. We
            have your back and put in our best to contribute to the growth of
            your entire team and organization. So, if you are looking for the
            right agency that’ll help you build a good online presence and bring
            in more conversions and revenue, we are right here!
          </p>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
