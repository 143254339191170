import React from "react";

const Newsletter = () => {
  return (
    <section
      id="newsletter"
      className="py-16 bg-gradient-to-r from-orange-400 via-pink-500 to-purple-600 text-white"
    >
      <div className="container mx-auto px-4 md:px-8 lg:px-16">
        <div className="flex flex-col lg:flex-row items-center justify-between space-y-8 lg:space-y-0 lg:space-x-8">
          {/* Left Content */}
          <div className="lg:w-1/2 text-center lg:text-left">
            <h2 className="text-4xl font-bold mb-4">
              Stay Updated with Our Latest News
            </h2>
            <p className="text-lg mb-6">
              Subscribe to our newsletter for exclusive insights, updates, and
              offers.
            </p>
          </div>

          {/* Right Content - Form */}
          <div className="lg:w-1/2 flex justify-center lg:justify-end">
            <form className="w-full max-w-md flex space-x-4">
              <input
                type="email"
                placeholder="Enter your email"
                className="w-full px-4 py-3 rounded-lg focus:outline-none focus:ring-4 focus:ring-pink-300 transition duration-300"
              />
              <button
                type="submit"
                className="bg-white text-purple-600 font-semibold px-6 py-3 rounded-lg hover:bg-purple-50 hover:text-purple-700 transition duration-300"
              >
                Subscribe
              </button>
            </form>
          </div>
        </div>

        {/* Decorative Element */}
        <div className="relative mt-12">
          <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-white w-16 h-16 rounded-full shadow-lg flex items-center justify-center animate-bounce">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-8 h-8 text-purple-600"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 4v16m8-8H4"
              />
            </svg>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Newsletter;
