// src/components/Footer.js
import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/dfehyjw5juj.png";
import iso from "../../assets/iso.png";

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-8">
      <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 text-center sm:text-left">
        {/* Logo Section */}
        <div className="ml-0 sm:ml-5">
          <img src={logo} alt="My Logo" className="h-10 mb-4 mx-auto sm:mx-0" />
          <p className="text-sm">
            Created by Team Ambey Nexus with ❤️
            <br/> © 2024 All Rights Reserved.
          </p>
          <div className="flex text-center align-center sm:flex-row">
            <img src={iso} alt="" className="h-20 mt-2" />
            <span className="text-center mt-6 text-xl font-bold ml-2">9001:2015 Certified</span>
          </div>
        </div>

        {/* Services Section */}
        <div>
          <h4 className="text-lg font-semibold mb-4">Services</h4>
          <ul className="space-y-2">
            <li>
              <Link to="/services" className="hover:underline">
                Website Development
              </Link>
            </li>
            <li>
              <Link to="/services" className="hover:underline">
                Graphics Designing
              </Link>
            </li>
            <li>
              <Link to="/services" className="hover:underline">
                Social Media Management
              </Link>
            </li>
            <li>
              <Link to="/services" className="hover:underline">
                SEO
              </Link>
            </li>
          </ul>
        </div>

        {/* Navigation Links */}
        <div>
          <h4 className="text-lg font-semibold mb-4">Navigation</h4>
          <ul className="space-y-2">
            <li>
              <Link to="/" className="hover:underline">
                Home
              </Link>
            </li>
            <li>
              <Link to="/about" className="hover:underline">
                About
              </Link>
            </li>
            <li>
              <Link to="/services" className="hover:underline">
                Services
              </Link>
            </li>
            <li>
              <Link to="/contact" className="hover:underline">
                Contact
              </Link>
            </li>
            <li>
              <Link to="/privacy-policy" className="hover:underline">
                Privacy & Policy
              </Link>
            </li>
          </ul>
        </div>

        {/* Contact & Social Media */}
        <div>
          <h4 className="text-lg font-semibold mb-4">Contact</h4>
          <ul className="space-y-2">
            <li>Phone: +91 7696637927</li>
            <li>Email: info@ambeynexus.com</li>

            <Link to="/faq" className="hover:underline">
              FAQ
            </Link>
          </ul>
          <div className="mt-4">
            <h4 className="text-lg font-semibold mb-2">Follow Us</h4>
            <div className="flex justify-center sm:justify-start space-x-4">
              <a href="https://www.facebook.com/share/SUm6Vp1S7uW1suBc/?mibextid=qi2Omg" className="hover:text-blue-400">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="#" className="hover:text-blue-500">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="https://www.instagram.com/ambeynexus?igsh=bjMxa2Vwend6d2l3" className="hover:text-red-600">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="#" className="hover:text-blue-700">
                <i className="fab fa-linkedin"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
        <div className="text-center md:text-[10rem] opacity-30 text-transform: uppercase font-bold sm:text-[2rem]">Ambey Nexus</div>
    </footer>
  );
};

export default Footer;
