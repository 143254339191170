import React from 'react';
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/home/HomePage';
import AboutUs from './components/about/AboutUs';
import Contact from './components/contact/Contact';
import Seo from './components/services/seo/Seo';
import WevDev from './components/services/development/WevDev';
import Gd from './components/services/graphicsDesigning/Gd';
import Services from './components/services/Servises'
import Training from './components/services/training/Training';
import ComputerBasics from './components/services/computerBasics/ComputerBasics';
import SpokenEnglishCoursePage from './components/services/training/SpokenEnglishCoursePage';
import PDPCoursePage from './components/services/training/PDPCoursePage ';
import PrivacyPolicy from './components/privacy/PrivacyPolicy';
import FAQPage from './components/faq/FaqPage';
import ScrollToTop from './components/utility/ScrollTop';



const App = () => {

  return (
    <Router>
      <div>
        <Navbar />
        <ScrollToTop />
        <main className="p-4">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/services" element={<Services />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/training" element={<Training />} />
            <Route path="/courses/web-development" element={<WevDev />} />
            <Route path="/training/social-media-marketing" element={<Seo />} />
            <Route path="/courses/graphic-design" element={<Gd />} />
            <Route path="courses/computer-basics" element={<ComputerBasics />} />
            <Route path="courses/spoken-english-courses" element={<SpokenEnglishCoursePage />} />
            <Route path="courses/personality-development-program" element={<PDPCoursePage />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/faq" element={<FAQPage />} />

          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
